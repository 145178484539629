import React from 'react'

const Skew = ({className, orientation = 'top-left'}) => {
  let scale
  switch (orientation) {
    case 'top-left':
      scale = '-1,-1'
      break
    case 'top-right':
      scale = '1,-1'
      break
    case 'bottom-left':
      scale = '-1,1'
      break
    case 'bottom-right':
      scale = '1,1'
      break
    default:
      scale = '1,1'
  }
  
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        style={{
          transform: `scale(${scale})`
        }}
      >
        <defs>
          <filter id="f1" x="0" y="0" width="200%" heigth="200%">
            <feOffset result="offOut" in="SourceAlpha" dx="-8" dy="-6" />
            <feColorMatrix
              type = "matrix" in="offOut" result="matrixOut"
              values="1     0     0     0     0
                      0     1     0     0     0
                      0     0     0     0     0.1
                      0     0     0     0.7     0 "/>
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="12" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <polygon
          fill="white"
          points="-40,-40 -40,100 185,-40"
          filter="url(#f1)"
        />
      </svg>
    </div>
  )
}

export default Skew