import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Scenario = () => {
  const data = useStaticQuery(graphql`
    query HomeScenario {
      scenario: markdownRemark(fields: {parent: {relativeDirectory: {eq: "home/scenario"}, name: {eq: "index"}}}) {
        frontmatter {
          taglines
          button
          buttonLink
        }
        html
      }
      items: allMarkdownRemark(filter: {fields: {parent: {relativeDirectory: {eq: "home/scenario"}, name: {ne: "index"}}}}, sort: {fields: fields___parent___name}) {
        nodes {
          html
        }
      }
    }
  
  `)

  const { scenario, items } = data

  return (
    <section className="scenario">
      <div 
        dangerouslySetInnerHTML={{ __html: scenario.html }}
        />

      <div className="scenario-items">
        {
          items.nodes.map((item, index) => (
            <div 
              key={index}
              className="scenario-items__item"
              dangerouslySetInnerHTML={{ __html: item.html }}
              />
          ))
        }
      </div>

      <div className="scenario-taglines">
        {
          scenario.frontmatter.taglines.map((tagline, index) => (
            <div
              key={ index }
              className="scenario-taglines__tagline"
              dangerouslySetInnerHTML={{__html: tagline}}
            />
          ))
        }
      </div>
    </section>
  )
}

export default Scenario