import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from '../image'
import Bg from '../bg'

const Hero = ({fullWidth}) => {
  const data = useStaticQuery(graphql`
    query HomeHero {
      markdownRemark(fields: {parent: {relativeDirectory: {eq: "home"}, name: {eq: "index"}}}) {
        frontmatter {
          button
          buttonLink
        }
        html
      }
    }
  `)

  const { markdownRemark: { frontmatter, html }} = data

  const className = (fullWidth)? 'hero full-width' : 'hero'

  return (
    <section className={className}>
      <Bg
        filename='home/background-homepage-header.jpg' 
        className='hero__bg'
        style={{
          backgroundPosition: 'center top'
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div>
          <Link to="/TermsAndConditions/"><button>{frontmatter.button}</button></Link>
        </div>
      </Bg>
      <div className="clip clip--bot" />
      <div className="hero-mobile">
        <Image filename='home/image-mobile.png' />
      </div>
    </section>
  )
}
  
export default Hero