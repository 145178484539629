import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const Mission = () => {
  const data = useStaticQuery(graphql`
    query HomeMIssion {
      markdownRemark(fields: {parent: {relativeDirectory: {eq: "home/mission"}, name: {eq: "index"}}}) {
        html
      }
    }
  `)

  const { markdownRemark: { html } } = data

  return (
    <section
      className="mission"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Mission