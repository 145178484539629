import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Benefit = ({fullWidth}) => {
  const data = useStaticQuery(graphql`
    query HomeBenefit {
      benefit: markdownRemark(fields: {parent: {relativeDirectory: {eq: "home/benefit"}, name: {eq: "index"}}}) {
        frontmatter {
          groups {
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            content
          }
          button
          buttonLink
        }
        html
      }
      benefitItems: allMarkdownRemark(filter: {fields: {parent: {relativeDirectory: {eq: "home/benefit"}, name: {ne: "index"}}}}) {
        nodes {
          html
          fields {
            parent {
              name
            }
          }
        }
      }
    }
  
  `)

  const { benefit, benefitItems } = data

  const className = (fullWidth)? 'benefit full-width' : 'benefit'

  return (
    <section className={className}>
      <div
        dangerouslySetInnerHTML={{ __html: benefit.html }}
      />

      <div className="benefit-grid">
        {
          benefit.frontmatter.groups.map((group, groupIndex) => {
            const position = (groupIndex % 2)? 'left' : 'right'
            const items = group.content.map((itemName, itemIndex) => {
              const item = benefitItems.nodes.find(benefitItem => benefitItem.fields.parent.name === itemName)

              if (!item) return ''

              return (
                <div
                  key={itemIndex}
                  className="benefit-grid__group-content-item"
                  dangerouslySetInnerHTML={{ __html: item.html}}
                />
              ) 

            })

            return (
              <div className={`benefit-grid__group benefit-grid__group--${position}`} key={groupIndex}>
                <div className="benefit-grid__group-image">
                  <Img fluid={group.image.childImageSharp.fluid} />
                </div>
                <div className="benefit-grid__group-content">
                  {items}
                </div>
              </div>
            )
          } )
        }
      </div>

      <div className="benefit__button">
        <Link to="/TermsAndConditions/"><button>{benefit.frontmatter.button}</button></Link>
      </div>
    </section>
  )
}

export default Benefit