import React from "react"

import SEO from "../components/seo"

import Hero from "../components/home/hero"
import Mission from "../components/home/mission"
import Benefit from "../components/home/benefit"
import Scenario from "../components/home/scenario"
import Social from "../components/home/social"
import Skew from "../components/skew"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Hero fullWidth />
    <Mission />
    <Skew className="full-width skew" orientation="top-right" />
    <Benefit />
    <Skew className="full-width skew" orientation="top-left" />
    <Scenario />
    <Social />
  </>
)

export default IndexPage
