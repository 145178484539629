import React from 'react'
import Icon from '@mdi/react'
import { mdiFacebookBox, mdiTwitter, mdiLinkedinBox } from '@mdi/js'
import { Link } from 'gatsby'

const Social = () => {

  return (
    <section className="social">
      <Link to="/AboutUs ">
        <button className="white">About us</button>
      </Link>
      
      <div className="divider"></div>
      <h2>Find us on</h2>
      <div className="social__icons">
        <a href="https://www.facebook.com/rigproplatform" target="_blank" rel="noopener noreferrer">
          <Icon path={mdiFacebookBox} color="#3b5998" size={2} />
        </a>
        <a href="htps://twitter.com/rigproplatform" target="_blank" rel="noopener noreferrer">
          <Icon path={mdiTwitter} color="#38A1F3" size={2} />
        </a>
        <a href="https://www.linkedin.com/company/rigpro" target="_blank" rel="noopener noreferrer">
          <Icon path={mdiLinkedinBox} color="#2867B2" size={2} />
        </a>
      </div>
    </section>
  )
}

export default Social