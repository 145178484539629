import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({filename}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: { sourceInstanceName: { eq: "markdown-pages"}}) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const image = data.placeholderImage.nodes.find(node => node.relativePath === filename)

  if (!image) return null

  return <Img fluid={image.childImageSharp.fluid} />
}

export default Image
